import React, { FC } from 'react';

import tokens from '../../themes/eneco/tokens';
import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const ElectricityGasHeating: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      d="M15.5 45C15.06 45 14.63 44.91 14.23 44.74C12.82 44.14 12.02 42.61 12.32 41.11L14.34 31H10.26C9.04 31 7.94 30.33 7.38 29.25C6.82 28.17 6.9 26.88 7.6 25.89L19.85 8.39002C20.73 7.12002 22.35 6.66002 23.78 7.26002C25.19 7.87002 26 9.39002 25.7 10.89L23.68 21H27.76C28.98 21 30.08 21.67 30.64 22.75C31.2 23.83 31.12 25.12 30.42 26.11L18.17 43.61C17.56 44.48 16.57 45 15.51 45H15.5ZM22.5 10C22.42 10 22.35 10.04 22.3 10.11L10.05 27.61C10 27.69 9.99 27.79 10.03 27.87C10.07 27.95 10.16 28 10.25 28H16.16C16.61 28 17.03 28.2 17.32 28.55C17.61 28.9 17.72 29.35 17.63 29.8L15.25 41.71C15.23 41.83 15.29 41.94 15.4 41.99C15.46 42.01 15.57 42.08 15.7 41.9L27.95 24.4C28 24.32 28.01 24.23 27.97 24.14C27.93 24.06 27.84 24.01 27.75 24.01H21.84C21.39 24.01 20.97 23.81 20.68 23.46C20.39 23.11 20.28 22.66 20.37 22.21L22.75 10.3C22.77 10.18 22.71 10.06 22.6 10.02C22.57 10.01 22.54 10 22.5 10Z"
      fill={color ?? tokens.iconColors.iconElectricity}
    />
    <path
      d="M63.04 35.69C61.89 34.76 60.11 34.77 58.97 35.69C58.56 36.02 49.01 43.8 49.01 53.98C49.01 64.16 58.57 71.95 58.97 72.27C59.55 72.74 60.27 72.99 61.01 72.99C61.75 72.99 62.47 72.73 63.05 72.27C63.46 71.94 73.01 64.13 73.01 53.98C73.01 43.83 63.46 36.02 63.05 35.69H63.04ZM61.16 69.94C61.05 70.03 60.96 70.03 60.85 69.94C60.49 69.65 52.01 62.72 52.01 53.99C52.01 45.26 60.76 38.11 60.85 38.03C60.91 37.99 60.97 37.98 61.01 37.98C61.05 37.98 61.11 37.98 61.17 38.03C61.26 38.1 70.01 45.23 70.01 53.98C70.01 62.73 61.53 69.64 61.17 69.94H61.16ZM62.12 47.98C61.55 47.34 60.45 47.34 59.88 47.98C59.72 48.16 56 52.39 56 57.74C56 63.09 59.72 67.32 59.88 67.5C60.16 67.82 60.57 68 61 68C61.43 68 61.83 67.82 62.12 67.5C62.28 67.32 66 63.09 66 57.74C66 52.39 62.28 48.16 62.12 47.98ZM61 63.94C60.1 62.51 59 60.24 59 57.74C59 55.24 60.11 52.97 61 51.54C61.89 52.97 63 55.24 63 57.74C63 60.24 61.9 62.51 61 63.94Z"
      fill={color ?? tokens.iconColors.iconGas}
    />
    <path
      d="M68.23 32.61C67.98 33.17 67.43 33.5 66.86 33.5C66.66 33.5 66.45 33.46 66.25 33.37C65.49 33.03 65.15 32.15 65.49 31.39C70.83 19.43 60.25 9.71002 60.15 9.62002C59.53 9.07002 59.48 8.12002 60.04 7.50002C60.59 6.88002 61.54 6.83002 62.16 7.39002C62.28 7.50002 74.45 18.69 68.24 32.62L68.23 32.61ZM55.91 23.96C55.66 14.34 48.37 7.67002 48.06 7.39002C47.45 6.83002 46.5 6.89002 45.94 7.50002C45.39 8.11002 45.43 9.06002 46.05 9.62002C46.12 9.68002 52.71 15.75 52.91 24.07C53.04 29.22 50.71 34.23 45.99 38.95C40.66 44.28 38.04 50.04 38.2 56.05C38.45 65.67 45.74 72.34 46.05 72.62C46.34 72.88 46.69 73.01 47.05 73.01C47.46 73.01 47.87 72.84 48.16 72.51C48.71 71.9 48.67 70.95 48.05 70.39C47.98 70.33 41.39 64.26 41.19 55.94C41.06 50.79 43.39 45.78 48.11 41.06C53.44 35.73 56.06 29.97 55.9 23.96H55.91ZM27.11 55.94C26.98 50.79 29.31 45.78 34.03 41.06C39.36 35.73 41.98 29.97 41.82 23.96C41.57 14.34 34.28 7.67002 33.97 7.39002C33.36 6.83002 32.41 6.89002 31.85 7.50002C31.3 8.11002 31.34 9.06002 31.96 9.62002C32.03 9.68002 38.62 15.75 38.82 24.07C38.95 29.22 36.62 34.23 31.9 38.95C26.57 44.28 23.95 50.04 24.11 56.05C24.36 65.67 31.65 72.34 31.96 72.62C32.25 72.88 32.6 73.01 32.96 73.01C33.37 73.01 33.78 72.84 34.07 72.51C34.62 71.9 34.58 70.95 33.96 70.39C33.89 70.33 27.3 64.26 27.1 55.94H27.11ZM13.82 50.46C14.07 49.67 13.64 48.83 12.85 48.57C12.06 48.32 11.22 48.75 10.96 49.54C6.79001 62.45 17.41 72.21 17.86 72.62C18.15 72.88 18.5 73.01 18.86 73.01C19.27 73.01 19.68 72.84 19.97 72.51C20.52 71.9 20.48 70.95 19.86 70.39C19.76 70.3 10.24 61.5 13.8 50.46H13.82Z"
      fill={color ?? tokens.iconColors.iconHeat}
    />
  </Illustration>
);
