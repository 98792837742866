import React, { FC, useId } from 'react';

import { styled } from '../stitches.config';
import tokens from '../themes/eneco/tokens';
import { TransformStitchesToSparky } from '../types';
import { extractVariantProps } from '../util/css/stitches';

export const SvgIcon = styled('svg', {
  display: 'block',

  variants: {
    /** The size of the `Illustration`, based on the `iconSizes` tokens. Default is `medium`.*/
    size: {
      small: {
        iconography: '$illustrationSmall',
      },
      medium: {
        iconography: '$illustrationMedium',
      },
      large: {
        iconography: '$illustrationLarge',
      },
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

const ownClassName = 'illustration-sparky';

const StyledStop = styled('stop', {});

export const Illustration: FC<React.PropsWithChildren<IllustrationProps>> = ({
  children,
  color = 'iconPrimary',
  size,
}) => {
  const variantProps = extractVariantProps({ size });

  const gradientId = useId();

  let fillValue;
  switch (color) {
    case 'currentColor':
      fillValue = 'currentColor';
      break;

    case 'brandGradient':
      fillValue = `url(#${gradientId})`;
      break;

    default:
      fillValue = `$${color}`;
  }

  return (
    <SvgIcon
      {...variantProps}
      className={ownClassName}
      aria-hidden="true"
      viewBox="0 0 80 80"
      css={{
        fill: fillValue,
      }}>
      {children}
      {color === 'brandGradient' && (
        <defs>
          <linearGradient id={gradientId}>
            <StyledStop offset="0%" css={{ stopColor: '$colors$brandGradientStart' }} />
            <StyledStop offset="100%" css={{ stopColor: '$colors$brandGradientEnd' }} />
          </linearGradient>
        </defs>
      )}
    </SvgIcon>
  );
};

type IllustrationVariants = TransformStitchesToSparky<typeof SvgIcon>;
type IconColors = keyof typeof tokens.iconColors | keyof typeof tokens.feedbackColors | 'brandGradient';

export interface IllustrationProps extends IllustrationVariants {
  /** The color of the Icon, by default it will inherit the `currentColor`.*/
  color?: IconColors;
}

Illustration.toString = () => `.${ownClassName}`;
